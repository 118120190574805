<template>
    <div class="box">
        <div class="background"></div>
        <div class="homepage-header flex x-left">
            <Header type="2" :navList="navList" :navCurrentId="navCurrentId">
                <div slot="search">
                    <div style="width:200px;"></div>
                </div>
            </Header>
        </div>
        <div class="homepage-content flex">
            <div class="homepage-list flex-item">
                <!-- 面包屑 -->
                <div class="flex pb5 x-left mb20 flex-wrap">
                    <div class="color999">您所在的位置：</div>
                    <a-breadcrumb class="row-1" style="max-width:1100px;">
                        <a-breadcrumb-item>
                            <router-link class="color999" :to="{ path: '/organization/org-album' }">相册</router-link>
                        </a-breadcrumb-item>
                        <a-breadcrumb-item>{{title}}</a-breadcrumb-item>
                    </a-breadcrumb>
                </div>
                <a-spin :spinning="loading" tip="Loading...">
                    <div class="flex x-left flex-wrap" v-if="albumDetailList.length">
                        <div @click="handlePreviewEvent(index)" class="album-item mb20" v-for="(item,index) in albumDetailList" :key="index">
                            <img class="album-item-img" @load="handleLoadEvent($event,index)"  :src="item.image_url | urlFilter(750)" alt="">
                        </div>
                    </div>
                    <a-empty style="margin-top:100px;" v-if="!albumDetailList.length && !loading" />
                </a-spin>
                <div class="t-c mt30" v-if="albumDetailTotal > 0">
                    <a-pagination :total="albumDetailTotal" v-model:current="albumParams.page" :page-size="albumParams.limit" show-quick-jumper @change="handlePageChangeEvent" :hideOnSinglePage="true" />
                </div>
            </div>
        </div>
        <photoswipe ref="photoswipe" :title="title" :picturelist="albumDetailList"></photoswipe>
        <Footer></Footer>
    </div>
</template>

<script>
import { orgDetailList } from "@/config/const";
import Header from "@/components/layout/layout-header";
import Footer from "@/components/layout/layout-footer";
import photoswipe from '@/components/photoswipe.vue';
import storage from 'store'
export default {
    name: "index",
    components: {
        Header, Footer, photoswipe
    },
    data() {
        return {
            navList: orgDetailList(storage.get('commiittee_id')),//顶部菜单
            navCurrentId: 5,//当前页面菜单对应下标
            meeting_id:this.$route.query.id,
            title:this.$route.query.title,
            loading: true,
            albumParams:{
                meeting_id: this.$route.query.id,
                limit: 20,
                page: 1,
            },
            albumDetailTotal:0,
            albumDetailList:[],
        }
    },
    created() {
        this.getAlbumList()
    },
    methods: {
        // 读取图片高度
        handleLoadEvent(e,index){
            this.$set(this.albumDetailList[index],'height',e.target.naturalHeight)
        },
        getAlbumList(){
            this.loading = true
            this.request.post('MeetingPicture',this.albumParams).then(res=>{
                this.albumDetailTotal = parseInt(res.data.count)
                this.albumDetailList = res.data.list
                this.loading = false
            })
        },
        // 查看图片
        handlePreviewEvent(index){
            this.$refs.photoswipe.showPhowoswipe(index);
        },
        // 切换页码
        handlePageChangeEvent(page) {
            window.scrollTo(0,0);
            this.albumParams.page = page
            this.getAlbumList()
        },
    }
}
</script>

<style scoped lang="less">
.background {
    width: 100%;
    height: 408px;
    background: linear-gradient(360deg, rgba(234, 249, 255, 0) 0%, #EAFAFF 100%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.homepage-header {
    width: 1200px;
    margin: 0 auto;

    &-title {
        max-width: 200px;
    }

    &-img {
        width: 88px;
        height: 88px;
        background: red;
    }
}
.homepage-content {
    width: 1240px;
    margin: 0 auto;
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    box-sizing: border-box;
}
.homepage-list {
    width: 100%;
    .mh{
        min-height: 500px;
    }
    .album-item{
        width: 285px;
        height: 160px;
        margin-right: 20px;
        &-img{
            width: 285px;
            cursor: pointer;
            height: 160px;
        }
    }
    .album-item:nth-child(4n+4){
        margin-right: 0;
    }
}
@media screen and (max-width:768px){
    .homepage-content{
        width: 100%;
        padding: 10px;
        .homepage-list{
            .album-item{
                width: 49%;
                margin-right: 2%;
                &:nth-child(4n+4),&:nth-child(2n+2){
                    margin-right: 0!important;
                }
                &-img{
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}
@media screen and (max-width:550px){
    .homepage-content{
        .homepage-list{
            .album-item{
                margin-bottom: 10px;
                height: 80px;
                &-img{
                    height: 80px;
                }
            }
        }
    }
}
</style>